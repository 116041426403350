import "./App.css";
import ReactGA from "react-ga";
import Home from "./components/home/Home";
import Unsubscribe from "./components/unsubscribe/Unsubscribe";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {
  //Google analytics
  const TRACKING_ID = "UA-231787646-1";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/beta" element={<Home />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
