import "./Map.css";
import { Map, ZoomControl, Overlay, Draggable } from "pigeon-maps";
import React from "react";
import PerformFilters from "../../res/Filter";
//import { Colors } from "../../res/Colors";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useBetween } from "use-between";
import useFormState from "../../GlobalState";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Currency from "react-currency-formatter";
import HouseImg from "../../data/house.png";
import ApartmentImg from "../../data/apartment.png";
import TownHouseImg from "../../data/townhouse.png";
import IndustrualHouse from "../../data/industrialHouse.png";
import Lawn from "../../data/lawn.png";
import Circle from "../../res/Circle";
import RegionMarker from "../../res/RegionMarker";

const CIRCLE_RADIUS = 10;

function getMarkerClassName(marker, isMarked) {
  //Check if marker details is selected
  if (isMarked.length > 0 && marker.id === isMarked[0].id) {
    return "markerImgSelected";
  }
  return "markerImg";
}

//keeps the watcherarea delete icon in the middle
function calculateCenterFromPoints(points) {
  var latitude = 0;
  var longitude = 0;

  points.forEach((point) => {
    latitude += point[0];
    longitude += point[1];
  });

  return [latitude / 4, longitude / 4];
}

function getMarkerImg(marker) {
  if (marker.category === "Fjölbýlishús") {
    return ApartmentImg;
  } else if (marker.category === "Einbýlishús") {
    return HouseImg;
  } else if (marker.category === "Raðhús" || marker.category === "Parhús") {
    return TownHouseImg;
  } else if (marker.category === "Atvinnuhúsnæði") {
    return IndustrualHouse;
  } else if (marker.category === "Lóð") {
    return Lawn;
  }
}

function MainMap() {
  const useSharedFormState = () => useBetween(useFormState);
  const {
    overlayItems,
    setOverLayItems,
    watcherAreas,
    setWatcherAreas,
    mapLocation,
    setMapLocation,
    mapZoom,
    setMapZoom,
  } = useSharedFormState();

  const deleteWatcherArea = (index) => {
    const newWatcherAreas = watcherAreas.filter(
      (item) => item !== watcherAreas[index]
    );
    setWatcherAreas(newWatcherAreas);
  };

  var filteredProperties = PerformFilters();
  return (
    <div className="Map">
      <Map
        defaultCenter={mapLocation}
        defaultZoom={mapZoom}
        onBoundsChanged={({ center, zoom }) => {
          setMapLocation(center);
          setMapZoom(zoom);
        }}
        zoomSnap={false}
      >
        <ZoomControl />
        {watcherAreas.map((item) => (
          <RegionMarker coordsArray={item} />
        ))}

        {filteredProperties.map((p) => (
          <Overlay
            key={p.id}
            width={35}
            anchor={[p.latitude, p.longitude]}
            onClick={() => setOverLayItems([p])}
          >
            <img
              className={getMarkerClassName(p, overlayItems)}
              src={getMarkerImg(p)}
              width={25}
              height={25}
              alt=""
              onClick={() => setOverLayItems([p])}
            />
          </Overlay>
        ))}

        {overlayItems.map((p) => (
          //TODO wrap in React component to resolve console warning
          <div className="detailsCard">
            <Card
              sx={{
                width: "100%",
                height: "100%",
                alignSelf: "center",
                borderRadius: "16px",
                backgroundColor: "rgba(245, 245, 245, 0.9)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <IconButton
                aria-label="close details"
                component="span"
                sx={{ alignSelf: "flex-start" }}
                onClick={() => setOverLayItems([])}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                sx={{ fontSize: 14, marginLeft: "0.5em", marginRight: "0.5em" }}
                color="text.secondary"
              >
                {p.address}, {p.category}
              </Typography>
              <Typography
                sx={{ fontSize: 14, marginLeft: "0.5em", marginRight: "0.5em" }}
                color="text.secondary"
              >
                {p.rooms} herbergi, {p.size} m<sup>2</sup>
              </Typography>
              <Typography
                sx={{ fontSize: 16 }}
                color="text.secondary"
                gutterBottom
              >
                <Currency
                  quantity={p.price}
                  currency="ISK"
                  pattern="##,### !"
                />
              </Typography>
              <CardActions>
                <Button
                  size="small"
                  sx={{ margin: "auto" }}
                  onClick={() =>
                    window.open(`http://fasteignir.visir.is/property/${p.id}`)
                  }
                >
                  Fleiri Upplýsingar
                </Button>
              </CardActions>
            </Card>
          </div>
        ))}

        {watcherAreas.map((item, index) =>
          item.map((c, i) => (
            <Draggable
              key={i}
              offset={[CIRCLE_RADIUS, CIRCLE_RADIUS]}
              anchor={c}
              onDragMove={(p) => {
                setWatcherAreas((ps) => {
                  const result = [...ps];
                  result[index][i] = p;
                  return result;
                });
              }}
            >
              <Circle r={CIRCLE_RADIUS} />
            </Draggable>
          ))
        )}

        {watcherAreas.map((item, index) => (
          <Overlay
            key={index}
            offset={[15, CIRCLE_RADIUS]}
            anchor={calculateCenterFromPoints(item)}
            className="watcherDelete"
          >
            <CloseIcon onClick={() => deleteWatcherArea(index)} />
          </Overlay>
        ))}
      </Map>
    </div>
  );
}

export default MainMap;
