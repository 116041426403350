import { useBetween } from "use-between";
import useFormState from '../GlobalState';
import * as constants from '../res/Constants';
import moment from 'moment'

const useSharedFormState = () => useBetween(useFormState);

function PerformFilters() {
  const { price, size, rooms, properties, apartment, house, townhouse, industrialHouse, lawn, addedToday, addedThisWeek } = useSharedFormState();

  var filterOptions = propertyTypeFilter(apartment, house, townhouse, industrialHouse, lawn);
  var returnProperties = [];

  //Property type filter
  returnProperties = properties.filter(p => filterOptions.includes(p.category));

  //Number of rooms filter
  returnProperties = returnProperties.filter(p => rooms[0] <= (p.bedrooms + p.bathrooms) && (p.bedrooms + p.bathrooms) <= rooms[1])

  //Size filter
  //Check if size is set to unlimited
  if(size[1] === constants.SLIDER_MAX_SIZE) {
    returnProperties = returnProperties.filter(p => p.size >= size[0])
  }
  else {
    returnProperties = returnProperties.filter(p => size[0] <= p.size && p.size <= size[1])
  }

  //Price filter
  //Check if price is set to unlimited
  if(price[1] === constants.SLIDER_MAX_PRICE) {
    returnProperties = returnProperties.filter(p => p.price >= price[0] * constants.PRICE_MULTIPLIER_FROM_API)
  }
  else {
    returnProperties = returnProperties.filter(p => (price[0] * constants.PRICE_MULTIPLIER_FROM_API) <= p.price && p.price <= (price[1] * 1000000))
  }

  //Date filter. Only show properties added today
  if(addedToday) {
    returnProperties = returnProperties.filter(p => moment(p.added).isSame(moment().format("YYYY-MM-DD")));
  }
  //Date filter. Only show properties added within last 7 days
  if(addedThisWeek) {
    //format is added to date7daysago to prevent lossing the 7th day in some cases
    const date7DaysAgo = moment().subtract(7, 'days').format("YYYY-MM-DD");
    returnProperties = returnProperties.filter(p => moment(p.added).isSameOrAfter(date7DaysAgo));
  }
  
  return returnProperties;
}

function propertyTypeFilter(apartment, house, townhouse, industrialHouse, lawn) {
  var optionArr = []

  if (apartment) { optionArr.push('Fjölbýlishús') }
  if (house) { optionArr.push('Einbýlishús') }
  if (townhouse) { optionArr.push('Raðhús', 'Parhús') }
  if (industrialHouse) { optionArr.push('Atvinnuhúsnæði') }
  if (lawn) { optionArr.push('Lóð') }

  return optionArr;
};

export default PerformFilters;