import './Email.css';
import React from "react";
import TextField from '@mui/material/TextField';
import { useBetween } from "use-between";
import useFormState from "../../GlobalState";

function Email() {

  const useSharedFormState = () => useBetween(useFormState);
  const { email, setEmail } = useSharedFormState();

  //Todo add email validation
  return (
      <div className='EmailMain'>
        <TextField 
          value={email}
          onChange={(newValue) => setEmail(newValue.target.value)}
          className="EmailField" 
          label="Sláðu inn netfang" 
          variant="outlined" />
      </div>
  );
}

export default Email;
