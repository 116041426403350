import "./Header.css";

import React from "react";

function Header() {
  return (
    <div className="header">
      <div className="logo">
        <h1>FastVakt.is</h1>
      </div>
      <div className="header-right">
        {/*Header menu btn*/}
      </div>
    </div>
  );
}

export default Header;
