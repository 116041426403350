import "./Actions.css";
import React from "react";
import Button from "@mui/material/Button";
import { useBetween } from "use-between";
import useFormState from "../../GlobalState";
import SubscribeData from "../../res/SubscribeData";
import { toast } from 'react-toastify';

const DisplayNotification = (successful, watcherAreas) => {
  if(successful) {
    toast.success('Vöktun skráð!', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      });
  } else if(!successful && watcherAreas.length < 1) {
    toast.error('Vinsamlegast bættu við nýju svæði', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      });
  }
   else {
    toast.error('Ekki tókst að skrá vöktun', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      });
  }
}

function Actions() {
  const useSharedFormState = () => useBetween(useFormState);
  const {
    watcherAreas,
    email,
    price,
    size,
    rooms,
    apartment,
    house,
    townhouse,
    industrialHouse,
    lawn,
  } = useSharedFormState();    
    return (
      <div className="ActionMain">
        <Button
          className="SubscribeBtn"
          sx={{
            width: "10em",
            height: "4em",
            borderRadius: "10px",
            fontWeight: "bold",
            padding: 1,
            margin: 2,
          }}
          onClick={() => {
            const requestOptions = {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(
                SubscribeData(
                  watcherAreas,
                  email,
                  price,
                  size,
                  rooms,
                  apartment,
                  house,
                  townhouse,
                  industrialHouse,
                  lawn
                )
              ),
            };
            fetch(
              "https://subscribe.fastvakt.is",
              requestOptions
            )
              .then((response) => response.json())
              //On error no token is returned from backend
              //TODO can be adjusted to determine notification based on return status code. Will need modification from backend
              //TODO do validation and display notification before executing request to the backend
              .then((data) => DisplayNotification(data.token, watcherAreas))
          }}
          variant="contained"
        >
          Skrá Vöktun
        </Button>
      </div>
    );
}

export default Actions;
