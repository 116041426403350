import "./Home.css";
import Options from "../options/Options.js";
import Map from "../map/Map.js";
import MapBeta from "../map/MapBeta";
import { useBetween } from "use-between";
import useFormState from "../../GlobalState";
import Actions from "../actions/Actions";
import Email from "../email/Email";
import Header from "../header/Header";
import MapActions from "../mapActions/MapActions";
import ReactGA from "react-ga";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Home() {
  const useSharedFormState = () => useBetween(useFormState);
  const { properties, setProperties } = useSharedFormState();

  //Google analytics
  const TRACKING_ID = "UA-231787646-1";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname);

  if (properties.length <= 0) {
    fetch("/data/listings.json")
      .then((response) => response.json())
      .then((data) => setProperties(data));
  }

  function displaySubscribeFunctionality() {
      return (
        <div className="SubscribeSection">
          <div className="EmailSection">
            <Email />
          </div>
          <div className="ActionSection">
            <Actions />
          </div>
        </div>
      );
  }

  return (
    <div className="App">
      <ToastContainer />
      <div className="HeaderSection">
        <Header />
      </div>
      <div className="MapSection">
        {window.location.href.includes("beta") ? <MapBeta /> : <Map />}
      </div>
      <div className="MapActions">
        <MapActions />
      </div>
      <div className="OptionsSection">
        <Options />
      </div>
      {displaySubscribeFunctionality()}
    </div>
  );
}

export default Home;
