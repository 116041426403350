import "./Options.css";
import * as React from "react"; //Todo update this
//import Button from '@mui/material/Button';
import Slider from "@mui/material/Slider";
import { useBetween } from "use-between";
import useFormState from "../../GlobalState";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as constants from "../../res/Constants";
import HouseImg from "../../data/house.png";
import ApartmentImg from "../../data/apartment.png";
import TownHouseImg from "../../data/townhouse.png";
import IndustrualHouseImg from "../../data/industrialHouse.png";
import LawnImg from "../../data/lawn.png";

function Options() {
  const useSharedFormState = () => useBetween(useFormState);
  const {
    size,
    setSize,
    rooms,
    setRooms,
    /*age, setAge, */ price,
    setPrice,
    apartment,
    setApartment,
    house,
    setHouse,
    townhouse,
    setTownhouse,
    industrialHouse,
    setIndustrialHouse,
    lawn,
    setLawn,
    addedToday,
    setAddedToday,
    addedThisWeek,
    setAddedThisWeek,
  } = useSharedFormState();

  //Sliders
  const handlePriceChange = (event, newValue) => {
    setPrice(newValue);
  };
  const handleSizeChange = (event, newValue) => {
    setSize(newValue);
  };
  const handleRoomsChange = (event, newValue) => {
    setRooms(newValue);
  };
  function handleOptionDateToday(newValue) {
    setAddedToday(newValue);
    setAddedThisWeek(false);
  }
  function handleOptionDatethisWeek(newValue) {
    setAddedThisWeek(newValue);
    setAddedToday(false);
  }
  //const handleAgeChange = (event, newValue) => { setAge(newValue); };

  return (
    <div>
      <Card className="optionCard">
        <CardContent>
          <div className="optionMain">
            <div className="optionLeft">
              <div className="optionPrice">
                <p>Verð</p>
                <Slider
                  getAriaLabel={() => "Price range"}
                  value={price}
                  step={10}
                  marks
                  min={constants.SLIDER_MIN_PRICE}
                  max={constants.SLIDER_MAX_PRICE}
                  onChange={handlePriceChange}
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) =>
                    value === constants.SLIDER_MAX_PRICE ? "Óendanlegt" : value
                  }
                />
              </div>
              <div className="optionSize">
                <p>Fermetrar</p>
                <Slider
                  getAriaLabel={() => "Size range"}
                  value={size}
                  step={15}
                  marks
                  min={constants.SLIDER_MIN_SIZE}
                  max={constants.SLIDER_MAX_SIZE}
                  onChange={handleSizeChange}
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) =>
                    value === constants.SLIDER_MAX_SIZE ? "Óendanlegt" : value
                  }
                />
              </div>
              <div className="optionDateAdded">
                <FormControlLabel
                  value="optionDateAddedToday"
                  control={
                    <Checkbox
                      checked={addedToday}
                      onChange={() => handleOptionDateToday(!addedToday)}
                      name="checked"
                      color="primary"
                    />
                  }
                  label="Nýtt í dag"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="optionDateAddedThisWeek"
                  control={
                    <Checkbox
                      checked={addedThisWeek}
                      onChange={() => handleOptionDatethisWeek(!addedThisWeek)}
                      name="checked"
                      color="primary"
                    />
                  }
                  label="Nýtt í vikunni"
                  labelPlacement="end"
                />
              </div>
            </div>
            <div className="optionRight">
              <div className="optionRooms">
                <p>Herbergi</p>
                <Slider
                  getAriaLabel={() => "Room count range"}
                  value={rooms}
                  step={1}
                  marks
                  min={0}
                  max={10}
                  onChange={handleRoomsChange}
                  valueLabelDisplay="on"
                />
              </div>
              <div className="checkBoxGroup">
                <div className="checkBoxGroupItem">
                  <FormControlLabel
                    value="apartmentFormControl"
                    control={
                      <Checkbox
                        checked={apartment}
                        onChange={() => setApartment(!apartment)}
                        name="checked"
                        color="primary"
                      />
                    }
                    label="Fjölbýli"
                    labelPlacement="end"
                  />
                  <div>
                    <img
                      src={ApartmentImg}
                      width={25}
                      height={25}
                      onClick={() => setApartment(!apartment)}
                      alt=""
                    />
                  </div>{" "}
                </div>
                <div className="checkBoxGroupItem">
                  <FormControlLabel
                    value="houseFormControl"
                    control={
                      <Checkbox
                        checked={house}
                        onChange={() => setHouse(!house)}
                        name="checked"
                        color="primary"
                      />
                    }
                    label="Einbýli"
                    labelPlacement="end"
                  />
                  <div>
                    <img
                      src={HouseImg}
                      width={25}
                      height={25}
                      onClick={() => setHouse(!house)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="checkBoxGroupItem">
                  <FormControlLabel
                    value="townhouseFormControl"
                    control={
                      <Checkbox
                        checked={townhouse}
                        onChange={() => setTownhouse(!townhouse)}
                        name="checked"
                        color="primary"
                      />
                    }
                    label="Raðhús/Parhús"
                    labelPlacement="end"
                  />
                  <div>
                    <img
                      src={TownHouseImg}
                      width={25}
                      height={25}
                      onClick={() => setTownhouse(!townhouse)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="checkBoxGroupItem">
                  <FormControlLabel
                    value="industrialHouseFormControl"
                    control={
                      <Checkbox
                        checked={industrialHouse}
                        onChange={() => setIndustrialHouse(!industrialHouse)}
                        name="checked"
                        color="primary"
                      />
                    }
                    label="Atvinnuhúsnæði"
                    labelPlacement="end"
                  />
                  <div>
                    <img
                      src={IndustrualHouseImg}
                      width={25}
                      height={25}
                      onClick={() => setIndustrialHouse(!industrialHouse)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="checkBoxGroupItem">
                  <FormControlLabel
                    value="lawnFormControl"
                    control={
                      <Checkbox
                        checked={lawn}
                        onChange={() => setLawn(!lawn)}
                        name="checked"
                        color="primary"
                      />
                    }
                    label="Lóð"
                    labelPlacement="end"
                  />
                  <div>
                    <img
                      src={LawnImg}
                      width={25}
                      height={25}
                      onClick={() => setLawn(!lawn)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Options;
