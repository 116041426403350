//import { Opacity } from "@material-ui/icons";

const getCentroid = (points) => {
  let cx = 0;
  let cy = 0;
  for (let i = 0; i < points.length; i++) {
    cx += points[i]["x"];
    cy += points[i]["y"];
  }

  return {
    "x": cx / points.length,
    "y": cy / points.length,
  }
}

const normalizePoints = (coordsArray) => {
  const points = coordsArray.map(p => {
    return {
      "x": p[0],
      "y": p[1],
    };
  });

  const centroid = getCentroid(points);

  for (let i = 0; i < points.length; i++) {
    points[i]["a"] = Math.atan2(points[i]["y"] - centroid["y"], points[i]["x"] - centroid["x"]);
  }

  points.sort((a, b) => a["a"] - b["a"]);

  return points.map(p => {
    return {
      "x": p["x"],
      "y": p["y"],
    };
  });
}

const RegionMarker = ({
  mapState: { width, height },
  latLngToPixel,
  coordsArray
}) => {
  const points = normalizePoints(coordsArray);
  let polygon_points = "";
  for (let i = 0; i < points.length; i++) {
    const p = latLngToPixel([points[i]["x"], points[i]["y"]]);
    polygon_points += p.join(",") + " ";
  }

  if (width < 0 || height < 0) {
    return "";
  }

  return (
    <>
      <svg width={width} height={height} style={{ top: 0, left: 0, position: "absolute", zIndex: "0" }}>
        <polygon
          points={polygon_points}
          style={{ fill: "Grey", fillOpacity: "0.6", stroke: "black", strokeWidth: "1" }}
        />
      </svg>
    </>
  );
};

export default RegionMarker;