const Circle = ({ r = 5 }) => {
    return (
      <svg height={r * 2} width={r * 2} style={{ position: "absolute", cursor: "pointer !important", zIndex: "1 !important" }}>
        <circle
          cx={r}
          cy={r}
          r={r - 1}
          stroke="black"
          strokeWidth={1}
          fill="#a6a6a6"
        />
      </svg>
    );
  };

export default Circle;