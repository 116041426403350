//import { FlashOnRounded } from '@material-ui/icons';
import { useState } from 'react';

const useFormState = () => {
    //Current map location
    const [mapLocation, setMapLocation] = useState([64.1338, -21.8980])
    //Current map zoom
    const [mapZoom, setMapZoom] = useState(12)
    //Watcher areas
    const [watcherAreas, setWatcherAreas] = useState([]);
    //User Email field
    const [email, setEmail] = useState('');
    //Overlay details
    const [overlayItems, setOverLayItems] = useState([]);

    // -- Filter properties --
    //Price range
    const [price, setPrice] = useState([10, 140]);
    //Size range
    const [size, setSize] = useState([30, 255]);
    //Number of rooms
    const [rooms, setRooms] = useState([0, 6]);
    //Age range
    const [age, setAge] = useState([35, 70]);
    //All properties
    const [properties, setProperties] = useState([]);
    //Fjölbýli settings
    const [apartment, setApartment] = useState(true);
    //Einbýli settings
    const [house, setHouse] = useState(true);
    //Rað-Parhús settings
    const [townhouse, setTownhouse] = useState(true);
    //Atvinnuhúsnæði settings
    const [industrialHouse, setIndustrialHouse] = useState(false);
    //Lóð
    const [lawn, setLawn] = useState(false);
    //Properties added today settings
    const [addedToday, setAddedToday] = useState(false);
    //Properties added this week settings
    const [addedThisWeek, setAddedThisWeek] = useState(false);

    return {
        mapZoom,
        setMapZoom,
        mapLocation,
        setMapLocation,
        watcherAreas,
        setWatcherAreas,
        price,
        setPrice,
        size,
        setSize,
        rooms,
        setRooms,
        age,
        setAge,
        properties,
        setProperties,
        apartment,
        setApartment,
        house,
        setHouse,
        townhouse,
        setTownhouse,
        industrialHouse,
        setIndustrialHouse,
        lawn,
        setLawn,
        overlayItems,
        setOverLayItems,
        addedToday,
        setAddedToday,
        addedThisWeek,
        setAddedThisWeek,
        email,
        setEmail,
    };
};

export default useFormState