import "./MapActions.css";
import * as React from "react"; //Todo update this
//import Button from '@mui/material/Button';
import { Overlay } from "pigeon-maps";
import { useBetween } from "use-between";
import useFormState from "../../GlobalState";
import Button from "@mui/material/Button";

function MapActions() {
  const useSharedFormState = () => useBetween(useFormState);
  const {
    setWatcherAreas,
    mapZoom,
    mapLocation,
  } = useSharedFormState();

  const addWatcherArea = () => {
    setWatcherAreas((ps) => {
      const nz = 0.2 / mapZoom;

      const result = [
        ...ps,
        [
          [mapLocation[0] - nz / 2, mapLocation[1] + nz],
          [mapLocation[0] - nz / 2, mapLocation[1] - nz],
          [mapLocation[0] + nz / 2, mapLocation[1] - nz],
          [mapLocation[0] + nz / 2, mapLocation[1] + nz],
        ],
      ];
      return result;
    });
  };

  return (
    <div>
        <Overlay className="watcherAction">
          <Button variant="contained" onClick={() => addWatcherArea()}>NÝTT SVÆÐI</Button>
        </Overlay>
    </div>
  );
}

export default MapActions;
