import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

async function PerformUnsubscribe(email, token) {
  //TODO Validate email

  const requestOptions = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ email: email, token: token }),
  };

  const response = await fetch(
    "https://unsubscribe.fastvakt.is",
    requestOptions
  );
  const unsubscribeSuccessful = response.ok;
  return unsubscribeSuccessful;
}

function Unsubscribe() {
  //Get query params
  const search = useLocation().search;
  const queryParamEmail = new URLSearchParams(search).get("email");
  const queryParamToken = new URLSearchParams(search).get("token");

  const [unsubscribeResult, setUnsubscribeResult] = useState(null);

  useEffect(() => {
    PerformUnsubscribe(queryParamEmail, queryParamToken)
      .then((unsubscribeSuccessful) => {
        setUnsubscribeResult(unsubscribeSuccessful);
      });
  }, [queryParamEmail, queryParamToken]);

  if (unsubscribeResult === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {unsubscribeResult === true ?
        <h1>Afskráning tókst!</h1>
        :
        <h1>Afskráning mistókst!</h1>
      }
    </div>
  );
}

export default Unsubscribe;
