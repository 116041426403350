const data = (
  watcherAreas,
  email,
  price,
  size,
  rooms,
  apartment,
  house,
  townhouse,
  industrialHouse,
  lawn,
) => {
    //Get selected house types
    var selectedHouseType = []
    if(apartment === true) { selectedHouseType.push("apartment") }
    if(house === true) { selectedHouseType.push("house") }
    if(townhouse === true) { selectedHouseType.push("townhouse") }
    if(industrialHouse === true) { selectedHouseType.push("industrial") }
    if(lawn === true) { selectedHouseType.push("land") }

    const include = watcherAreas.map((area) => {
        return {
            "points": area.map(point => {
                return {
                    "longitude": point[1],
                    "latitude": point[0],
                };        
            }),
        };
    });

  return (
    {
        "email": email,
        "price": {
            "min": price[0],
            "max": price[1],
        },
        "rooms": {
            "min": rooms[0],
            "max": rooms[1],
        },
        "squares": {
            "min": size[0],
            "max": size[1], 
        },
        "areas": {
            "include": include,
            "exclude": [],
        },
        "types": selectedHouseType
    }
  )
};

export default data;
